import AniLink from "gatsby-plugin-transition-link/AniLink";
import React, { useRef } from "react";

import { buildImageObj, cn, getProjectUrl } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import useScaleImageEffect from "../lib/useScaleImageEffect";
import PortableText from "./portableText";

import styles from "./project-preview.module.css";
import { large, responsiveMicro } from "./typography.module.css";
import { pageTransition } from "../lib/transition-config";

function ProjectPreview(props) {
  const {
    slug,
    previewImage,
    title,
    _rawFormattedTitle,
    formattedTitle,
    descriptor,
    isInArchive,
  } = props;
  const mediaInner = useRef(null);

  const formattedTitleBlocks = _rawFormattedTitle ? _rawFormattedTitle : formattedTitle;

  useScaleImageEffect(mediaInner, mediaInner, true);

  return (
    <AniLink
      {...pageTransition}
      className={cn(styles.root, isInArchive ? styles.inArchive : "")}
      to={getProjectUrl(slug.current)}
    >
      {previewImage && previewImage.asset && (
        <div className={styles.leadMediaThumb}>
          <div ref={mediaInner} className={styles.leadMediaInner}>
            <img
              src={imageUrlFor(buildImageObj(previewImage))
                .width(600)
                .height(600)
                .quality(100)
                .auto("format")
                .url()}
              alt={previewImage.alt}
            />
          </div>
        </div>
      )}
      <div className={styles.text}>
        <h3 className={cn(large, styles.title)}>
          {formattedTitleBlocks ? <PortableText blocks={formattedTitleBlocks} /> : title}
        </h3>
        <div className={cn(responsiveMicro, styles.descriptor)}>{descriptor}</div>
      </div>
    </AniLink>
  );
}

export default ProjectPreview;
