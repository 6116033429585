import AniLink from "gatsby-plugin-transition-link/AniLink";
import React from "react";
import { cn } from "../lib/helpers";
import { RiArrowRightLine } from "react-icons/ri";
import ProjectPreview from "./project-preview";
import TextBrandCard from "./text-brand-card";
import ImageBrandCard from "./image-brand-card";
import LottieBrandCard from "./lottie-brand-card";
import { responsiveTitle1, large, pageTitle } from "./typography.module.css";
import { pageTransition } from "../lib/transition-config";
import plusIcon from "../images/Capacity_PlusAccent.svg";

import styles from "./project-preview-grid.module.css";

function ProjectPreviewGrid(props) {
  const { title, nodes, browseMoreHref, buttonCopy, isInArchive } = props;

  return (
    <div className={cn(styles.root, isInArchive ? styles.inArchive : "")}>
      <div className={styles.content}>
        {isInArchive && (
          <div className={cn(styles.plusTop, styles.plus)}>
            <img src={plusIcon} alt="plus accent" />
            <img src={plusIcon} alt="plus accent" />
          </div>
        )}
        {title && <h2 className={cn(responsiveTitle1, pageTitle, styles.title)}>{title}</h2>}
        <ul className={styles.grid}>
          {nodes &&
            nodes
              .filter((node) => !node.disabled)
              .map((node, index) => {
                let el = null;
                if (!isInArchive) {
                  el = <ProjectPreview {...node} isInArchive={isInArchive} />;
                } else {
                  switch (node._type) {
                    case "project":
                      el = <ProjectPreview {...node} isInArchive={isInArchive} />;
                      break;

                    case "textBrandCard":
                      el = <TextBrandCard {...node} />;
                      break;

                    case "imageBrandCard":
                      el = <ImageBrandCard {...node} />;
                      break;

                    case "lottieBrandCard":
                      el = <LottieBrandCard {...node} />;
                      break;

                    default:
                      el = null;
                      break;
                  }
                }

                return <li key={index}>{el}</li>;
              })}
          {browseMoreHref && (
            <li key="browseMore" className={styles.browseMoreNav}>
              <AniLink {...pageTransition} to={browseMoreHref} className={large}>
                {buttonCopy}
                <RiArrowRightLine className={styles.browseMoreArrow} />
              </AniLink>
            </li>
          )}
        </ul>

        {isInArchive && (
          <div className={cn(styles.plusBottom, styles.plus)}>
            <img src={plusIcon} alt="plus accent" />
            <img src={plusIcon} alt="plus accent" />
          </div>
        )}
      </div>
    </div>
  );
}

ProjectPreviewGrid.defaultProps = {
  title: "",
  nodes: [],
  browseMoreHref: "",
};

export default ProjectPreviewGrid;
