import React from "react";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import QArchiveSettings from "../queries/QArchiveSettings";
import ArchivePage from "../components/archive-page";

const Work = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  return (
    <QArchiveSettings>
      {({ nodes, pageTitle }) => (
        <Layout>
          <SEO title="Work" />
          <ArchivePage title={pageTitle} nodes={nodes} />
        </Layout>
      )}
    </QArchiveSettings>
  );
};

export default Work;
